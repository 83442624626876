.Notification {
    display: flex !important;
    flex-direction: column !important;
    align-items: center !important;
    padding: 20px !important;
    overflow: hidden !important;
}

.NotificationPaper {
    display: flex !important;
    flex-direction: column !important;
    align-items: center !important;
    gap: 10px !important;
    margin-top: 17px !important;
    overflow-y: scroll !important;
    margin-bottom: 70px !important;
    width: 100% !important;
    border-radius: 8px !important;
    opacity: 1 !important;
}

.cardPaper {
    box-shadow: 0px 2px 4px #00000014 !important;
    border-radius: 8px !important;
    opacity: 1 !important;
    padding: 20px !important;
    background: white !important;
    border: 1px solid #fff !important;
    width: 100% !important;
    cursor: pointer !important;
}
.unReadedcardPaper {
    box-shadow: 0px 2px 4px #00000014 !important;
    border-radius: 8px !important;
    opacity: 1 !important;
    padding: 20px !important;
    background: white !important;
    border: 1px solid #AFD8F3 !important;
    width: 100% !important;
    cursor: pointer !important;
}
/* .cardPaper:hover{
    border: 1px solid #AFD8F3 !important;
} */

.cardcontent {
    display: flex !important;
    flex-direction: row !important;
    gap: 1.5px !important;
}

.cardaction {
    display: flex !important;
    padding: 15px 20px 0px 0px !important;
    align-items: center !important;
    justify-content: space-between !important;
}

.cardfooter {
    display: flex !important;
    flex-direction: row !important;
    gap: 10 !important;
    margin-left: 20 !important;
}

.cardfootername {
    display: flex !important;
    flex-direction: row !important;
    margin-left: 20 !important;
    justify-content: space-between !important;
}

.cardfooternametag {
    display: flex !important;
    flex-direction: row !important;
    gap: 10 !important;
}

.cardactions {
    display: flex !important;
    justify-content: space-between !important;
    width: 100% !important;
}

.NotificationHeader {
    display: inline-block !important;
    width: 100% !important
}
.headerTag{
    display: flex !important;
    flex-direction: row !important;
    align-items: center !important;
    justify-content: center !important;
}
.markBtn{
    text-transform: inherit !important;
    background: none !important;
}
.viewBtn{
    text-transform: inherit !important;
    background: none !important;
}