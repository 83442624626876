:root {
    --primary1: #F7F7F7;
    --primary2: #FFFFFF;
    --primary3: #7C7C7C;
    --primary4: #C4549A;
    --primary5: #A0457F;
    --primary6: #F7F5F5;
    --primary7: #363636;
    --primary8: #4A6DF4;
    --primary9: #E94362;
    --primary10: #427BE0;
    --primary11: #00000014;
    --primary12: #000000;
    --primary13: #E6A438;
    --primary14: #3B074F;
    --primary15: #DC6943;
    --primary16: #4AA0D8;
    --primary17: #923693;
    --primary18: #FEFDFD;
    --primary19: #EAEAEA;
    --primary20: #EDF0F2;
    --primary21: #F7F8F9;
    --primary22: #ECEFF1;
    --primary23: #00000029;
    --primary24: #DEDEDE;
    --primary25: #939393;
    --primary26: #11213F;
    --primary27: #5F5F5F;
    --primary28: #D9BAF4;
    --primary29: #661CA5;
    --primary30: #FFECEE;
    --primary31: #BB2A33;
    --primary32: #E9F5CE;
    --primary33: #436F00;
    --primary34: #D9F5FD;
    --primary35: #0063B8;
    --primary36: #FEEFCB;
    --primary37: #925400;
    --primary38: #557FF6;
    --primary39: #26B4FE40;
    --primary40: #406FF5;
    --primary41: #F6A266;
    --primary42: #F4883B;
    --primary43: #65D195;
    --primary44: #39C477;
    --primary45: #AB7AD5;
    --primary46: #9355C9;
    --primary47: #AEAEAE;
    --primary48: #E93323;
    --primary49: #313131;
    --primary50: #DDDDDD;
    --primary51: #D2D3D5AC;
    --primary52: #0000000F;
    --primary53: #D2D3D5;
    --primary54: #00000000;
    --primary55: #E2DFDF;
    --primary56: #6F6F6F;
    --primary57: #212427;
    --primary58: #FFD0D9;
    --primary59: #808991;
    --primary60: #303030;
    --primary61: #B5B5B5;
    --primary62: #0000001F;
    --primary63: #959DAA;
    --primary64: #FCD6BB;
    --primary65: #B47800;
    --primary66: #707070;
    --primary67: #C0C2C3;
    --primary68: #7A808B;
    --primary69: #ADADAD;
    --primary70: #84B9FD;
    --primary71: #001E45;
    --primary72: #FF6361;
    --primary73: #848484;
    --primary74: #CCCCCC;
    --primary75: #AFD8F3;
    --primary76: #1976d2;
    --primary77: #9D9D9D;
    --primary78: #868686;
    --primary79: #555353;
    --primary80: #727272;
    --primary81: #507AD9;
    --primary82: #747474;
    --primary83: #9C9EA6;
}