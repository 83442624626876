@import url(../colors/colors.css);

.primarybutton {
    min-width: 104px !important;
    height: 44px !important;
    width: 104px !important;
    /* UI Properties */
    background: var(--primary10) 0% 0% no-repeat padding-box !important;
    border-radius: 5px !important;
    opacity: 1 !important;
    text-transform: initial !important;
}

.loginbutton {
    min-width: 104px !important;
    height: 52px !important;
    /* UI Properties */
    background: var(--primary10) 0% 0% no-repeat padding-box !important;
    border-radius: 5px !important;
    opacity: 1 !important;
    text-transform: initial !important;
    color: var(--primary2) !important;
}
.loginbutton:disabled{
    background: var(--primary63) 0% 0% no-repeat padding-box !important;
}

.primary1button {
    padding: 12px 44px !important;
    /* min-width: 171px !important; */
    max-width: 171px !important;
    height: 44px !important;
    /* UI Properties */
    white-space: nowrap;
    background: var(--primary10) 0% 0% no-repeat padding-box !important;
    border-radius: 5px !important;
    opacity: 1 !important;
    text-transform: capitalize !important;
}

.secondarybutton {
    background: var(--primary2) 0% 0% no-repeat padding-box !important;
    border: 1px solid var(--primary50) !important;
    border-radius: 16px !important;
    align-self: end !important;
    text-transform: initial !important;
}

.seccancelbutton {
    width: 97px !important;
    height: 40px !important;
    /* UI Properties */
    background: var(--primary2) 0% 0% no-repeat padding-box !important;
    border: 1px solid var(--primary8) !important;
    border-radius: 5px !important;
    opacity: 1;
    text-transform: initial !important;
}

.primary2button {
    min-width: 136px !important;
    /* width: 171px !important; */
    height: 40px !important;
    /* UI Properties */
    background: var(--primary10) 0% 0% no-repeat padding-box !important;
    border-radius: 5px !important;
    opacity: 1 !important;
    text-transform: capitalize !important;
}

.cancelbutton {
    width: 97px !important;
    height: 40px !important;
    /* UI Properties */
    background: var(--primary2) 0% 0% no-repeat padding-box !important;
    border: 1px solid var(--primary59) !important;
    border-radius: 5px !important;
    opacity: 1;
    align-self: end !important;
    text-transform: initial !important;
}

.yesbutton {
    width: 97px !important;
    height: 40px !important;
    /* UI Properties */
    background: var(--primary10) 0% 0% no-repeat padding-box !important;
    border: 1px solid var(--primary10) !important;
    border-radius: 5px !important;
    opacity: 1;
    align-self: end !important;
    text-transform: initial !important;
}

.yesbutton:disabled {
    cursor: none !important;
    background: #7C7C7C !important;
    border: 1px solid #7C7C7C !important;
}

.listbutton {
    background: none !important;
    /* align-items: baseline !important; */
}

.activeIcon path {
    fill: #427BE0 !important;
}

.listbutton .svgIcon path {
    fill: #7C7C7C !important;
}

.listbutton:hover .svgIcon path {
    fill: #427BE0 !important;
}

.listbutton:active {
    color: #427BE0 !important;
    fill: #427BE0 !important;
}

.listbutton:active .svgIcon path {
    fill: #427BE0 !important;
}

.listbutton::selection {
    color: #427BE0 !important;
    fill: #427BE0 !important;
}

.buttonCurrent {
    color: #427BE0 !important;
    align-items: baseline !important;
}

.listbutton .hocss {
    color: #7C7C7C !important;
}

.listbutton:hover .hocss {
    color: #427BE0 !important;
}

.listbutton:focus .svgIcon path {
    fill: #427BE0 !important;
    color: #427BE0 !important;
}

.listbutton:focus .hocss {
    color: #427BE0 !important;
    fill: #427BE0 !important;
}

.tablecell {
    background: white !important;
}

.tablecell:hover {
    background: #427BE0 !important;
}

.tablecell:focus {
    background: #427BE0 !important;
}

.uploadButton {
    height: 40px !important;
    /* UI Properties */
    background: #FFFFFF 0% 0% no-repeat padding-box !important;
    box-shadow: none !important;
    opacity: 1;
    text-transform: initial !important;
    width: fit-content !important;
    width: 195px !important;
    text-align: start !important;
    justify-content: flex-start;
    color: #7C7C7C !important;
    border: 1px solid #E2E2E2 !important;
    border-radius: 4px !important;
}

.okbutton {
    width: 97px !important;
    height: 40px !important;
    /* UI Properties */
    background: var(--primary10) 0% 0% no-repeat padding-box !important;
    border: 1px solid var(--primary10) !important;
    border-radius: 5px !important;
    opacity: 1;
    /* align-self:end !important; */
    text-transform: initial !important;
}

.dropdown {
    width: 215px;
    height: 40px;
    /* UI Properties */
    background: #FFFFFF 0% 0% no-repeat padding-box;
    border: 1px solid #EBEAEA;
    border-radius: 4px;
    opacity: 1;
}