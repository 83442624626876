.rootDiv{
    padding: 41px !important;
    padding-right: 5px !important;
}
.rootDivPrimary{
    width: 64px !important;
    height: 64px !important;
    border-radius: 5px !important;
    opacity: 1 !important;
    justify-content: center !important;
    display: flex !important;
    align-items: center !important;
}
.defPro{
    background: #C4549A 0% 0% no-repeat padding-box !important;
    border: 1px solid #A0457F !important;
}
.rootDivPrimary1{
    padding-top: 29px !important;
    display: flex !important;
    flex-direction: column !important;
}
.rootDivPrimary2{ 
    display: flex !important; 
    flex-direction: row !important; 
    align-items: center !important
}
.rootDivPrimary3{ 
    display: flex !important; 
    flex-direction: row !important; 
    justify-content: space-between !important; 
    align-items: center !important; 
    width: fit-content !important
}
.rootDivPrimary4{ 
    height: 100% !important;
    /* background: #427BE0 !important; */
    display: flex !important;
    align-items: center !important; 
    margin-bottom: 80px !important;
}
.iconBtn{ 
    gap: 1 !important;
    margin: 0px 0px 18px 0px !important;
}
.iconLogotBtn{ 
    gap: 1 !important;
    margin: 150px 0px 0px 0px !important;
}
.iconEditBtn{ 
    margin-left: 18px !important
}
.iconTag{
    overflow: initial !important;
    height: fit-content !important;
    margin-left: -2px !important;
}
.typoalign{
    margin-left: 10px !important;
}
.copybtn{
    width: 32px !important;
    height: 32px !important;
    min-width: 0px !important;
    margin-left: 5px !important;
    padding: 0px !important;
}
.copybtn:hover {
    background: rgb(216, 224, 255) !important;
}
.pencilIcon path{
    fill: #4A6DF4 !important;
    color: #4A6DF4 !important;
}
.userName{
    overflow: hidden !important;
    text-overflow: ellipsis !important;
    white-space: nowrap !important;
}
.sameLine{
    display: flex !important;
    flex-direction: row !important;
    gap: 10px !important;
    padding-left: 41px !important;
    align-items: center !important;
}
.ListButton{
    padding: 6px 0px 6px 0px !important;
    text-transform: initial !important;
    width: 100% !important;
    justify-content: flex-start !important;
    font-family: RobotoNormal, sans-serif !important ;
    font-size: 1rem !important;
    line-height: 1.3125rem !important;
    letter-spacing: 0.32px !important;
    color: #7c7c7c !important;
    fill: #7c7c7c !important;
    margin-top: 20px !important;
}
.logoutBtn{
    padding: 6px 0px 6px 0px !important;
    text-transform: initial !important;
    width: 100% !important;
    justify-content: flex-start !important;
    font-family: RobotoNormal, sans-serif !important ;
    font-size: 1rem !important;
    line-height: 1.3125rem !important;
    letter-spacing: 0.32px !important;
    color: #E94362 !important;
}
.ListButton path{
    color: #7c7c7c !important;
    fill: #7c7c7c !important;
}
.ListButton:hover{
    color: #427BE0 !important;
    fill: #427BE0 !important;
}
.ListButton:hover path{
    color: #427BE0 !important;
    fill: #427BE0 !important;
}
.ListButtonSelected{
    padding: 6px 0px 6px 0px !important;
    text-transform: initial !important;
    width: 100% !important;
    justify-content: flex-start !important;
    font-family: RobotoMedium, sans-serif !important ;
    font-size: 1rem !important;
    line-height: 1.3125rem !important;
    letter-spacing: 0.32px !important;
    color: #427BE0 !important;
    margin-top: 20px !important;
}
.editIcon{
    width: 24px !important;
    height: 24px !important;
    background: #FFFFFF 0% 0% no-repeat padding-box !important;
    border: 1px solid #A0457F !important;
    opacity: 1 !important;
    padding: 6px !important;
    margin-top: 53px !important;
    margin-right: -59px !important;
    position: absolute !important;
}
.penIcon path{
    fill: #C4549A !important;
}
.emailSpan{
    width: 82% !important;
    word-break: break-all !important;
}