.SnackbarComponent {
    background: #F4FFF9 0% 0% no-repeat padding-box !important;
    box-shadow: 0px 13px 20px #0000001A !important;
    border-radius: 0px 5px 5px 0px !important;
    opacity: 1 !important;
    min-height: 55px !important;
    min-width: 30px !important;
    justify-content: space-between !important;
    display: flex !important;
    align-items: center;
    padding: 0px 20px 0px 20px !important;
    gap: 15px !important;
}
.linediv{
    width: 5px !important;
    height: 55px !important;
    /* UI Properties */
    background: #2BC7B0 0% 0% no-repeat padding-box !important;
    box-shadow: 0px 3px 10px #0000001A !important;
    border-radius: 5px 0px 0px 5px !important;
    opacity: 1 !important;
}
.snackdiv{
    display: flex !important;
    flex-direction: row !important;
}
.ErrorSnackbarComponent {
    background: #f5e2e2 0% 0% no-repeat padding-box !important;
    box-shadow: 0px 13px 20px #0000001A !important;
    border-radius: 0px 5px 5px 0px !important;
    opacity: 1 !important;
    min-height: 55px !important;
    min-width: 30px !important;
    justify-content: space-between !important;
    display: flex !important;
    align-items: center;
    padding: 0px 20px 0px 20px !important;
    gap: 15px !important;
}
.Errorlinediv{
    width: 5px !important;
    height: 55px !important;
    /* UI Properties */
    background: #fc6464 0% 0% no-repeat padding-box !important;
    box-shadow: 0px 3px 10px #0000001A !important;
    border-radius: 5px 0px 0px 5px !important;
    opacity: 1 !important;
}