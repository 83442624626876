@font-face {
  font-family: "robotobold";
  src: local("RobotoBold"),
    url(./Styles/font/Roboto/Roboto-Bold.ttf) format("truetype");
}
@font-face {
  font-family: "robotomedium";
  src: local("RobotoMedium"),
    url(./Styles/font/Roboto/Roboto-Medium.ttf) format("truetype");
}
@font-face {
  font-family: "robotonormal";
  src: local("RobotoNormal"),
    url(./Styles/font/Roboto/Roboto-Regular.ttf) format("truetype");
}
@font-face {
  font-family: "robotoitalic";
  src: local("RobotoItalic"),
    url(./Styles/font/Roboto/Roboto-Italic.ttf) format("truetype");
}