.audioListRoot{
    width: 40% !important;
    height: inherit !important;
    border-top-left-radius: 3px;
    border-right-style: solid !important;
    border-right-color: #949494 !important;
    border-right-width: 0.02rem !important;
}
.headerAudio{
    display: flex !important;
    justify-content: center !important;
    align-items: center !important;
    height: 59px !important;
    border-top-left-radius: 3px;
}
.divider{
    border-color: #949494 !important;
}
.listroot{
    width: 100% !important;
    height: calc(100% - 60px) !important;
    border-bottom-left-radius: 3px;
    overflow-x: hidden !important;
    overflow-y: auto !important;
}
.secondarydivider{
    border-color: #949494 !important;
}
.active {
    background-color: #427BE0 !important;
    opacity: 1 !important;
    height: 65px !important;
    padding-left: 15px !important;
    padding-right: 15px !important;
}
.unactive{
    background-color: #313131 !important;
    height: 65px !important;
    padding-left: 15px !important;
    padding-right: 15px !important;
    cursor: pointer !important;
}
.datetime{
    display: flex !important;
    flex-direction: row !important;
    justify-content: space-between !important;
}