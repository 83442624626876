.ProfileDialog {
    width: 242px !important;
    height: 242px !important;
    display: flex !important;
    align-items: center !important;
    justify-content: center !important;
    flex-direction: column !important;
}
.dialogPaper {
    height: 338px !important;
    width: 364px !important;
    display: flex !important;
    flex-direction: column !important;
    align-items: center !important;
    justify-content: center !important;
}
.profileDiv {
    width: 100px !important;
    height: 100px !important;
    /* UI Properties */
    /* background: #c4549a 0% 0% no-repeat padding-box !important; */
    /* border: 1px solid #a0457f !important; */
    border-radius: 5px !important;
    opacity: 1 !important;
    display: flex !important;
    align-items: center !important;
    justify-content: center !important;
}
.defprofileDiv {
    width: 100px !important;
    height: 100px !important;
    /* UI Properties */
    background: #c4549a 0% 0% no-repeat padding-box !important;
    border: 1px solid #a0457f !important;
    border-radius: 5px !important;
    opacity: 1 !important;
    display: flex !important;
    align-items: center !important;
    justify-content: center !important;
}
.addprofileBtn {
    width: 242px !important;
    height: 35px !important;
    background: #427be0 0% 0% no-repeat padding-box !important;
    border-radius: 5px !important;
    opacity: 1 !important;
    text-transform: initial !important;
    margin-top: 45px !important;
}
.changeimagetn {
    width: 130px !important;
    height: 35px !important;
    background: #427be0 0% 0% no-repeat padding-box !important;
    border-radius: 5px !important;
    opacity: 1 !important;
    text-transform: initial !important;
    margin-top: 45px !important;
}
.removeimagetn {
    width: 130px !important;
    height: 35px !important;
    background: var(--primary2) 0% 0% no-repeat padding-box !important;
    border: 1px solid #427be0 !important;
    border-radius: 5px !important;
    opacity: 1 !important;
    align-self: end !important;
    text-transform: initial !important;
}
.twobtns {
    display: 'flex' !important;
    justify-content: 'space-evenly' !important;
    gap: '16px' !important;
}
.icondiv {
    position: absolute !important;
    top: 10px !important;
    right: 10px !important;
    display: flex !important;
}
