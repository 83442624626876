.root{
    display: flex !important;
    align-items: center !important;
    justify-content: center !important;
     width: 100% !important;
    flex-direction: column !important;
    gap: 2px !important;
}
.try_again{
    text-transform: inherit !important;
    color: white !important;
    border-radius: 20px !important;
    background: #1976d2 !important;
    font-family: RobotoNormal, sans-serif !important ;
    margin-top: 20px !important;
}