.draggable_Player_root{
    width: 366px !important;
    height: 149px !important;
    bottom: 3% !important;
    right: 1% !important;
    position: fixed !important;
    z-index: 3000 !important;
    background: #313131 0% 0% no-repeat padding-box !important;
    box-shadow: 0px 0px 6px #00000029 !important;
    border-radius: 11px !important;
    opacity: 1  !important;
}
.draggable_parent_player_root{
    flex-direction: row !important;
    display: flex !important;
    width: 707px !important;
    height: 465px !important;
    top: 50% !important;
    left: 50% !important;
    margin-top: -233px !important;
    margin-left: -354px !important;
    position: fixed !important;
    z-index: 3000 !important;
    background: #313131 0% 0% no-repeat padding-box !important;
    box-shadow: 0px 0px 6px #00000029 !important;
    border-radius: 11px !important;
    opacity: 1  !important;
}
.cross_Btn{
    position: absolute !important;
    margin-top: -27px !important;
    right: 5px !important;
}
.name_root{
    display: flex !important;
    justify-content: center !important;
    align-items: center !important;
    margin-top: 8px !important;
}
.audio_root{
    padding: 0px 24px 24px 24px !important;
    display: flex !important;
    flex-direction: column !important;
    height: 100% !important;
}
.control_btn_root{
    display: flex !important;
    justify-content: center !important;
    align-items: center !important;
    flex-direction: row !important;
    gap: 10px !important;
}
.control_btn{
    width: 50px !important;
    height: 50px !important;
    padding: 0px !important;
}
.PlayIcon{
    width: 45px !important;
    height: 45px !important;
}
.slideComponent{
    padding-top: 20px !important;
    display: flex !important;
    justify-content: center !important;
    flex-direction: column !important;
}
.sliderTimer{
    display: flex !important;
    align-items: center !important;
    justify-content: space-between !important;
}
.durTime{
    margin-right: -15px !important;
}
.curTime{
    margin-left: -15px !important;
}
.loder_rootDiv{
    display: flex !important;
    justify-content: center !important;
    align-items: center !important;
    height: 100% !important;
}
.crossIcon{
    width: 30px !important;
    height: 32px !important;
}
.audio_header{
    display: flex !important;
    justify-content: space-between !important;
}
.playerRoot{
    display: flex !important;
    flex-direction: column !important;
    align-items: center !important;
    padding-top: 30px !important;
}

.circleprogresss{
    display: flex !important;
    justify-content: center !important;
    align-items: center !important;
    height: 100% !important;
    width: 100% !important;
}
.dialogaudioplayer{
    width: 60% !important;
}
.playerheader{
    position: absolute !important;
    top: 0 !important;
    padding: 65px !important;
    display: flex !important;
    flex-direction: column !important;
    align-items: center !important;
}
.sameLine{
    padding-top: 10px !important;
    display: flex !important;
    justify-content: center !important;
    align-items: center !important;
}