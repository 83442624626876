.root{
    min-width: 359px !important;
    background: #2BC7B0 0% 0% no-repeat padding-box !important;
    box-shadow: 0px 13px 20px #0000001A !important;
    border-radius: 6px !important;
    opacity: 1 !important;
}
.toolbar{
    display: flex !important;
    flex-direction: row !important;
    justify-content: space-between !important;
}
.expand{
    color: white !important;
}