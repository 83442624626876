.siteContentWraper {
    opacity: 1;
    height: 100%;
    min-height: 100vh;
    background: #F7F7F7 0% 0% no-repeat padding-box;
}
@media screen and (min-width: 500px) {
    .siteContentWraper {
        width: calc(100% - 252px);
    }
}
